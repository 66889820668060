const Projects = () => {
    const promptFileSpec = (formSelector, btnSelector, modalId) => {
        const form = $(formSelector);
        const button = form.find(btnSelector);
        const modal = $(modalId);

        button.on('click', (e, from) => {
            if (!from) {
                e.preventDefault();
                const projectPromise = new Promise((resolve) => {
                    modal.modal('show');
                    $('button[name="skip_file_spec"', modal).on('click', () => {
                        resolve();
                    });
                    modal.on('hidden.bs.modal', (e) => {
                        $('button[name="skip_file_spec"', modal).off('click');
                    });
                });
                projectPromise.then(() => {
                    button.trigger('click', 'promptFileSpec')
                }).catch(() => {
                    // do nothing
                });
            }
        });
    }

    return {
        promptFileSpec
    };
};
export default Projects;
