const RegionCountrySelect = (form) => {
    const bind = () => {
        const countrySelect = form.find('.country-select');
        const regionSelect = form.find('.region-select');
        const csrfToken = $('meta[name="csrf-token"]').attr('content');

        countrySelect.on('change', (e) => {
            const countryId = e.currentTarget.value;
            if (countryId) {
                fetch(`/api/regions/options?country_id=${countryId}`, {
                    'X-CSRF-Token': csrfToken
                })
                    .then(resp => resp.json())
                    .then(options => {
                        options = options.map(option => `<option value="${option[1]}">${option[0]}</option>`)
                            .join('');
                        if (options.length > 0) {
                            options = ['<option>Choose One</option>'] + options;
                        }
                        regionSelect.html(options);

                        if (options.length === 0) {
                            regionSelect.attr('disabled', true);
                        } else {
                            regionSelect.attr('disabled', false);
                        }
                    });
            }
        });

        // hide region select initially if no valid options
        if ($('.region-select').children('option[value!=""]').length === 0) {
            regionSelect.attr('disabled', true);
        }
    };

    return {
        bind
    };
};

export default RegionCountrySelect;
