const ProductFilters = () => {
    const bind = () => {
        let filterToggle = $('.products-sidebar .product-filters-toggle');
        let filterPanel = $('.products-sidebar .product-filters');

        filterToggle.on('click', (e) => {
            e.preventDefault();
            filterPanel.toggleClass('show');
        });
    };
    return { bind };
};

export default ProductFilters;
