// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "jquery";
import "bootstrap";
import ProductFilters from "../src/productFilters";
import Projects from "../src/projects";
import RegionCountrySelect from "../src/regionCountrySelect";

const Modals = () => {
    const bind = () => {
        $('button.open-modal').on('click', (e) => {
            e.preventDefault();
            const btn = $(e.currentTarget);
            const action = btn.data('modal-action')
            fetch('/modals/' + action, {
                cache: 'no-cache',
                credentials: 'same-origin'
            })
                .then(resp => resp.text())
                .then(modalBody => {
                    const modal = $(modalBody);
                    $('body').append(modal);
                    modal.modal('show');
                });
        });
    }
    return {
        bind
    };
};

const SavingsCalculator = (form_id) => {
    const form = $(`#${form_id} form`);
    const results = $(`#${form_id} .results`);
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const calculate = (num_cans, num_chafers, fuel_cost) => {
        const chafer_meal_cost = num_cans * fuel_cost
        const chafer_annual_cost = chafer_meal_cost * 365;
        const meal_annual_cost = chafer_annual_cost * num_chafers;

        return {
            chafer_meal_cost,
            chafer_annual_cost,
            meal_annual_cost
        };
    };

    form.submit(e => {
        e.preventDefault();
        const numCans = $('input[name="num_cans"]', form).val();
        const numChafers = $('input[name="num_chafers"]', form).val();
        const fuelCost = $('input[name="fuel_cost"]', form).val();
        const savings = calculate(numCans, numChafers, fuelCost);

        results.find('.one_meal_annual_cost').text(formatter.format(savings.meal_annual_cost))
        results.find('.two_meal_annual_cost').text(formatter.format(2 * savings.meal_annual_cost))
        results.find('.three_meal_annual_cost').text(formatter.format(3 * savings.meal_annual_cost))

        results.removeClass('d-none');
        return false;
    });
};
window.SavingsCalculator = SavingsCalculator;

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    Modals().bind();
});

window.SBW = {
    ProductFilters,
    Projects,
    RegionCountrySelect
};

window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
